import { useEffect, useMemo, useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { addROErrorLog, decodeImage } from "../features/globalFunctions";
import { formatTime, formatShortTimeSeconds } from "../features/formatTime";
import myUUid from "../features/myUUid";
import ROIcon from "../components/ROIcon";
import ShowROIcons from "../components/ShowROIcons";

import axios from "axios";

const Appointment = () => {
  const navigate = useNavigate();

  const [visitor, setVisitor] = useState({});

  console.log("Appointment.jsx Main Call: ", visitor ? visitor : "");

  const [boolFormValidated, setBoolFormValidated] = useState(true);

  let [searchParams, setSearchParams] = useSearchParams();

  let token = searchParams.get("token") ? searchParams.get("token") : "";

  const [formSubmitted, setFormSubmitted] = useState(false);

  const startCameraButton = useRef();
  const photoButton = useRef();
  const video = useRef();
  const canvas = useRef();
  const msg = useRef();

  useEffect(() => {
    if (video && video.current) {
      video.current.style.display = "none";
    }

    if (canvas && canvas.current) {
      canvas.current.style.display = "none";
    }

    if (startCameraButton && startCameraButton.current) {
      startCameraButton.current.style.display = "block";
    }

    if (photoButton && photoButton.current) {
      photoButton.current.style.display = "none";
    }

    try {
      console.log("Appointment.jsx Get Data from servers");

      // const connStr = server + "/api/visitors/appointment-token/" + token;
      ///api/visitors/appointment-token/:token
      const connStr = "/api/visitors/appointment-token/" + token;

      if (token && token !== "") {
        //dispatch(sendAppointmentToken(token));
        console.log("useeffect sendappointmenttoken:" + connStr);
        //const response = await
        axios
          .get(connStr)
          .then((response) => {
            toast.success("Token Sent to Decode Server");
            // console.log("Token Sent to Decode Server");
            console.log("Response: ", response);
            // console.log("Response Data: ", response.data);
            // console.log("Response Data Token: ", response.data.token);
            setVisitor(response.data.token);
          })
          .catch((error) => {
            console.log("Error: ", error);
            toast.error("Error: " + error);
          });
      }
    } catch (error) {
      console.log("Appointment.jsx Dispatch(all) Error: ", error);
      addROErrorLog(
        "Appointment Dispatch(all) Error:  " + error.toString(),
        "AppointmentForm"
      );
      toast.error(error);
    }
  }, [token]); //[navigate, user, dispatch, isError, isPartsError]

  //Appointment Functions
  const uriPic = useRef();

  const [formData, setFormData] = useState({
    VisitorFirstName: visitor ? visitor.VisitorFirstName : "",
    visitorurname: visitor ? visitor.visitorurname : "",
    VisitorIDNumber: visitor ? visitor.VisitorIDNumber : "",
    VisitorContactNumber: visitor ? visitor.VisitorContactNumber : "",
    VisitorContactNumber2: visitor ? visitor.VisitorContactNumber2 : "",
    VisitorEmail: visitor ? visitor.VisitorEmail : "",
    VisitorCompanyName: visitor ? visitor.VisitorCompanyName : "",
    VisitorArmed: "",
    VisitorLocalContactName: visitor ? visitor.VisitorLocalContactName : "",
    VisitorReason: visitor ? visitor.VisitorReason : "",
    VisitorTimeStampIn: "",
    VisitorTimeStampOut: "",
    VisitorAppointmentTimeStamp: visitor
      ? visitor.VisitorAppointmentTimeStamp
      : "",
    VisitorIDNumberType: "",
    VisitorIDCountryOfIssue: "",
    VisitorGender: "",
    VisitorDriverRestrictions: "",
    VisitorLicenseIssueNumber: "",
    VisitorLicenseCountryOfIssue: "",
    VisitorPrdpCode: "",
    VisitorVehicleCodes: "",
    VisitorVehicleRestrictions: "",
    VisitorFlags: "",
    VisitorNotes: "",
    VisitorVehicleTimeStampIN: null,
    VisitorVehicleTimeStampOut: null,
    VisitorVehiclePlant: "",
    VisitorVehicleSerial: "",
    VisitorVehicleAssemplyPlant: "",
    VisitorVehicleProductionSeq: "",
    VisitorVehicleDiscNR: "",
    VisitorVehicleREGNR: "",
    VisitorVehicleLICREGNR: "",
    VisitorVehicleType: "",
    VisitorVehicleMake: "",
    VisitorVehicleModel: "",
    VisitorVehicleColor: "",
    VisitorVehicleVIN: "",
    VisitorVehicleEnginNR: "",
    VisitorVehicleExpDate: "",
    VisitorVehicleFlags: "",
    VisitorVehicleNotes: "",
    VisitorPhotoBlob: null,
    VisitorPhotoFlags: "",
    VisitorPhotoNotes: "",
  });

  useMemo(() => {
    //console.log('VisitorApplication.jsx useMemo: ', formData, ' Token: ', token)
    if (video && video.current) {
      video.current.style.display = "none";
    }

    if (canvas && canvas.current) {
      canvas.current.style.display = "none";
    }

    if (startCameraButton && startCameraButton.current) {
      startCameraButton.current.style.display = "block";
    }

    if (photoButton && photoButton.current) {
      photoButton.current.style.display = "none";
    }

    if (visitor) {
      setFormData({
        VisitorFirstName: visitor ? visitor.VisitorFirstName : "",
        VisitorSurname: visitor ? visitor.VisitorSurname : "",
        VisitorIDNumber: visitor ? visitor.VisitorIDNumber : "",
        VisitorContactNumber: visitor ? visitor.VisitorContactNumber : "",
        VisitorContactNumber2: visitor ? visitor.VisitorContactNumber2 : "",
        VisitorEmail: visitor ? visitor.VisitorEmail : "",
        VisitorCompanyName: visitor ? visitor.VisitorCompanyName : "",
        VisitorArmed: "",
        VisitorLocalContactName: visitor ? visitor.VisitorLocalContactName : "",
        VisitorReason: visitor ? visitor.VisitorReason : "",
        VisitorTimeStampIn: "",
        VisitorTimeStampOut: "",
        VisitorAppointmentTimeStamp: visitor
          ? visitor.VisitorAppointmentTimeStamp
          : "",
        VisitorIDNumberType: "",
        VisitorIDCountryOfIssue: "",
        VisitorGender: "",
        VisitorDriverRestrictions: "",
        VisitorLicenseIssueNumber: "",
        VisitorLicenseCountryOfIssue: "",
        VisitorPrdpCode: "",
        VisitorVehicleCodes: "",
        VisitorVehicleRestrictions: "",
        VisitorFlags: "",
        VisitorNotes: "",
        VisitorVehicleTimeStampIN: null,
        VisitorVehicleTimeStampOut: null,
        VisitorVehiclePlant: "",
        VisitorVehicleSerial: "",
        VisitorVehicleAssemplyPlant: "",
        VisitorVehicleProductionSeq: "",
        VisitorVehicleDiscNR: "",
        VisitorVehicleREGNR: "",
        VisitorVehicleLICREGNR: "",
        VisitorVehicleType: "",
        VisitorVehicleMake: "",
        VisitorVehicleModel: "",
        VisitorVehicleColor: "",
        VisitorVehicleVIN: "",
        VisitorVehicleEnginNR: "",
        VisitorVehicleExpDate: "",
        VisitorVehicleFlags: "",
        VisitorVehicleNotes: "",
        VisitorPhotoBlob: visitor ? visitor.VisitorPhotoBlob : "",
        VisitorPhotoFlags: "",
        VisitorPhotoNotes: "",
      });
    }
  }, [visitor]);

  const onSubmit = async (e) => {
    e.preventDefault();

    //setFormSubmitted(false)

    //console.log('VisitorFormNew.jsx onSubmit: ', visitorData)
    console.log("FormData: ", formData);
    //await dispatch(updateVisitor(formData))
    //setFormSubmitted(true)

    //navigate('/visitor')

    toast.success("Form Submitted");
    console.log("FormData: ", formData);

    const connStr = "/api/visitors/";
    const data = {
      ...formData,
      VisitorID: visitor.VisitorID,
    };

    //const response = await

    axios
      .put(connStr, data)
      .then((response) => {
        toast.success("Data Sent to Server");
        console.log("connStr: ", connStr);
        console.log("Data Sent to Server: ", data);
        console.log("Response: ", response);
        // console.log("Response Data: ", response.data);
        // console.log("Response Data Token: ", response.data.token);
        //setVisitor(response.data.token);
        setFormSubmitted(true);
        setVisitor(null);
        clearForm();
      })
      .catch((error) => {
        console.log("Error: ", error);
        toast.error("Error: " + error);
      });
  };

  // useMemo(() => {
  //   if (formSubmitted) {
  //     toast.success("Form Submitted");
  //     clearForm();
  //   }
  // }, [formSubmitted]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const updateFormData = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const clearForm = () => {
    setFormData({
      VisitorFirstName: "",
      visitorurname: "",
      VisitorIDNumber: "",
      VisitorContactNumber: "",
      VisitorContactNumber2: "",
      VisitorEmail: "",
      VisitorCompanyName: "",
      VisitorArmed: "",
      VisitorLocalContactName: "",
      VisitorReason: "",
      VisitorTimeStampIN: "",
      VisitorTimeStampOut: "",
      VisitorAppointmentTimeStamp: "",
      VisitorIDNumberType: "",
      VisitorIDCountryOfIssue: "",
      VisitorGender: "",
      VisitorDriverRestrictions: "",
      VisitorLicenseIssueNumber: "",
      VisitorLicenseCountryOfIssue: "",
      VisitorPrdpCode: "",
      VisitorVehicleCodes: "",
      VisitorVehicleRestrictions: "",
      VisitorFlags: "",
      VisitorNotes: "",
      VisitorVehicleTimeStampIN: null,
      VisitorVehicleTimeStampOut: null,
      VisitorVehiclePlant: "",
      VisitorVehicleSerial: "",
      VisitorVehicleAssemplyPlant: "",
      VisitorVehicleProductionSeq: "",
      VisitorVehicleDiscNR: "",
      VisitorVehicleREGNR: "",
      VisitorVehicleLICREGNR: "",
      VisitorVehicleType: "",
      VisitorVehicleMake: "",
      VisitorVehicleModel: "",
      VisitorVehicleColor: "",
      VisitorVehicleVIN: "",
      VisitorVehicleEnginNR: "",
      VisitorVehicleExpDate: "",
      VisitorVehicleFlags: "",
      VisitorVehicleNotes: "",
      VisitorPhotoBlob: null,
      VisitorPhotoFlags: "",
      VisitorPhotoNotes: "",
    });

    if (video && video.current) {
      video.current.style.display = "none";
    }

    if (canvas && canvas.current) {
      canvas.current.style.display = "none";
    }

    if (startCameraButton && startCameraButton.current) {
      startCameraButton.current.style.display = "block";
    }

    if (photoButton && photoButton.current) {
      photoButton.current.style.display = "none";
    }
  };

  const onCameraButton = () => {
    console.log("Start Camera");
    toast.success("Camera button clicked");
    // let video = document.querySelector('#video')
    // let canvas = document.querySelector('#canvas')
    // let clickPhoto = document.querySelector('#click-photo')

    if (video.current.srcObject) {
      video.current.srcObject.getTracks().forEach((track) => {
        track.stop();
        video.current.srcObject = null;
      });
    } else {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            width: 320,
            height: 240,
            frameRate: 15,
          },
        })
        .then((stream) => {
          toast.success("Camera Starting");
          video.current.srcObject = stream;
          video.current.play();
          video.current.width = 320;
          video.current.height = 240;
          video.current.style.display = "block";
          canvas.current.style.display = "none";
          photoButton.current.style.display = "block";
          startCameraButton.current.style.display = "none";
          //startCameraButton.current.textafter = 'Stop Camera'
        })
        .catch((error) => {
          console.log("Error: ", error);
          toast.error("Error: " + error);
        });
    }
  };

  const onPhotoClick = () => {
    console.log("Click Photo");
    console.log(
      "Video: ",
      video.current.videoWidth,
      video.current.videoHeight,
      video
    );

    canvas.current.width = video.current.videoWidth;
    canvas.current.height = video.current.videoHeight;
    //canvas.current.getContext('2d').drawImage(video.current, 0, 0, canvas.current.width, canvas.current.height)
    canvas.current.getContext("2d").drawImage(video.current, 0, 0, 320, 240);
    if (video.current.srcObject) {
      video.current.style.display = "none";
      canvas.current.style.display = "block";
      photoButton.current.style.display = "none";
      startCameraButton.current.style.display = "block";

      video.current.srcObject.getTracks().forEach((track) => {
        track.stop();
        video.current.srcObject = null;
      });
    }
    getCanvasDataURL();
  };

  const uploadImage = (e) => {
    console.log("Upload Image");
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let img = new Image();
      img.src = reader.result;
      img.onload = () => {
        // canvas.current.width = img.width
        // canvas.current.height = img.height
        // canvas.current.getContext('2d').drawImage(img, 0, 0, img.width / 2, img.height / 2)
        canvas.current.width = 360;
        canvas.current.height = 240;
        canvas.current.getContext("2d").drawImage(img, 0, 0, 360, 240);
        video.current.style.display = "none";
        canvas.current.style.display = "block";
        getCanvasDataURL();
      };
    };
  };

  const getCanvasDataURL = () => {
    console.log("getCanvasDataURL");
    if (canvas.current) {
      let image_data_url = canvas.current.toDataURL("image/jpeg");
      // msg.current.innerText = image_data_url
      // msg.current.style.wordwrap = 'break-word'
      // msg.current.style.fontweight = 'bold'
      // textLength.current.innerText = 'Image Length: ' + image_data_url.length
      //uriPic.current.src = image_data_url

      setFormData((prevState) => ({
        ...prevState,
        ["VisitorPhotoBlob"]: image_data_url,
      }));

      return image_data_url;
    }
    return "";
  };

  //End Appointment functions

  return (
    <>
      <section className="pageHead container w-100 p-0 m-0">
        <section className="pageFunctions container"></section>
      </section>
      {!formSubmitted ? (
        <form onSubmit={onSubmit}>
          <div className="pageContent container pb-4">
            {/*Main container bootstrap row with 2 columns. First col for Picture upload. Second col for Visitor Details*/}
            <section
              className="p-4 mt-3 pb-4 rounded-2"
              style={{ border: "1px solid #5d6567" }}
            >
              <div className="row">
                {/* <!-- Upload of picture --> */}
                <div className="col-lg-2">
                  <div className="row">
                    <div className="col">
                      <div className="row">
                        <div className="col">
                          <div className="visitorsImage rounded">
                            <canvas
                              ref={canvas}
                              id="visitorsImage"
                              width="220"
                              height="200"
                              className="visitorsImage rounded"
                            ></canvas>
                            <video
                              ref={video}
                              id="visitorsImage"
                              width="220"
                              height="200"
                              // className="visitorsImage"
                              autoPlay
                            ></video>
                          </div>
                        </div>
                      </div>

                      {/* Upload picture buttons */}
                      <div className="row">
                        <div className="col  AppointmentFormGridContainer AppointmentFormGrid">
                          <div className="uploadButtons my-auto pt-5">
                            {/* First button  */}
                            <div className="container">
                              <input
                                onChange={(e) => uploadImage(e)}
                                type="file"
                                accept="image/jpeg, image/png, image/jpg"
                                style={{ display: "none" }}
                                id="uploadInput"
                              />
                              <div
                                className="uploadPhoto rounded-1 text-white p-1"
                                onClick={() =>
                                  document.getElementById("uploadInput").click()
                                }
                              >
                                <ROIcon
                                  icon="icoImage"
                                  size={24}
                                  className="icoSVG w-100 p-0 m-0 fullDiv"
                                  textafter="Upload Photo"
                                  ROiconAfterStyle="align-bottom p-0 m-0 ps-2 "
                                  tooltip="Upload Photo"
                                  usefill="true"
                                  isLink={false}
                                  // onClickCallback={() => document.getElementById('uploadInput').click()}
                                />
                              </div>
                            </div>
                            {/* Second Button */}
                            <div
                              ref={startCameraButton}
                              className="container pt-2"
                            >
                              <button
                                type="button"
                                className="UseCamera rounded-1 text-white p-1"
                              >
                                <div className="">
                                  {" "}
                                  <ROIcon
                                    icon="icoCam"
                                    size={24}
                                    className="icoSVG float-start"
                                    ROiconAfterStyle="align-bottom p-0 m-0 ps-2"
                                    //textafter='Employees'
                                    //tooltip={cameras ? cameras[0].CameraName : ''}
                                    tooltip="Start Camera"
                                    textafter="Use Camera"
                                    usefill="true"
                                    color1={"#fff"}
                                    color2={"#000"}
                                    color3={"#000"}
                                    isLink={false}
                                    onClickCallback={() => onCameraButton()}
                                  />
                                </div>
                              </button>
                            </div>

                            <div ref={photoButton} className="container pt-2">
                              <button
                                type="button"
                                className="UseCamera rounded-1 text-white p-1"
                              >
                                <div className="">
                                  {" "}
                                  <ROIcon
                                    icon="icoCam"
                                    size={24}
                                    className="icoSVG float-start"
                                    ROiconAfterStyle="align-bottom p-0 m-0 ps-2"
                                    //textafter='Employees'
                                    //tooltip={cameras ? cameras[0].CameraName : ''}
                                    tooltip="Take Photo"
                                    textafter="Take Photo"
                                    usefill="true"
                                    color1={"#fff"}
                                    // color2={'#000'}
                                    // color3={'#000'}
                                    isLink={false}
                                    onClickCallback={() => onPhotoClick()}
                                  />
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Right COL with visitor details */}
                {/* <!-- Visitors application --> */}

                <div className="col-xl-8  visitorsColumn">
                  <div className="row  visitorsColumn ms-2">
                    <div className="container ms-4">
                      <div className="row">
                        <h1 className="text Visitors-Aplication">
                          <ROIcon
                            icon="icoVisitors"
                            size={60}
                            className="icoSVG EmployeeSVG"
                            //textafter='Employees'
                            //tooltip={cameras ? cameras[0].CameraName : ''}
                            usefill="true"
                            // color1="#f21637"
                            isLink={false}

                            // onClickCallback={() => onCameraButton()}
                          />
                          Visitors Application
                        </h1>
                      </div>
                      {/* <!-- first row of user details --> */}
                      <div className="row">
                        <div className="col-md-4 ApplicationPadding">
                          <div className="">First Name</div>
                          <div className="form-group mt-1">
                            <input
                              type="text"
                              className="form-control"
                              id="VisitorFirstName"
                              name="VisitorFirstName"
                              placeholder="First name"
                              title="First name"
                              value={formData ? formData.VisitorFirstName : ""}
                              required
                              // value={email}
                              onChange={(e) => {
                                onChange(e);
                                //vaildateNewUserForm()
                              }}
                              // ref={valUserEmailRef}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 ApplicationPadding">
                          <div className="">Surname</div>
                          <div className="form-group mt-1">
                            <input
                              type="text"
                              className="form-control"
                              id="VisitorSurname"
                              name="VisitorSurname"
                              placeholder="Surname"
                              title="Surname"
                              value={formData ? formData.VisitorSurname : ""}
                              required
                              // value={email}
                              onChange={(e) => {
                                onChange(e);
                                //vaildateNewUserForm()
                              }}
                              // ref={valUserEmailRef}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 ApplicationPadding">
                          <div className="">ID Document/Passport</div>
                          <div className="form-group mt-1">
                            <input
                              type="text"
                              className="form-control "
                              id="VisitorIDNumber"
                              name="VisitorIDNumber"
                              placeholder="ID/Passport Number"
                              title="ID/Passport Number"
                              value={formData ? formData.VisitorIDNumber : ""}
                              required
                              maxLength={'13'}
                              // value={email}
                              onChange={(e) => {
                                onChange(e);
                                //vaildateNewUserForm()
                              }}
                              // ref={valUserEmailRef}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <!-- second row of user details --> */}
                      <div className="row mt-2">
                        <div className="col-md-4 ApplicationPadding">
                          <div className="">Mobile</div>
                          <div className="form-group mt-1">
                            <input
                              type="text"
                              className="form-control"
                              id="VisitorContactNumber"
                              name="VisitorContactNumber"
                              placeholder="Mobile Number"
                              title="Mobile Number"
                              value={
                                formData ? formData.VisitorContactNumber : ""
                              }
                              required
                              // value={email}
                              onChange={(e) => {
                                onChange(e);
                                //vaildateNewUserForm()
                              }}
                              // ref={valUserEmailRef}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 ApplicationPadding">
                          <div className="">Landline</div>
                          <div className="form-group mt-1">
                            <input
                              type="text"
                              className="form-control"
                              id="VisitorContactNumber2"
                              name="VisitorContactNumber2"
                              placeholder="Landline"
                              title="Landline"
                              value={
                                formData ? formData.VisitorContactNumber2 : ""
                              }
                              //required
                              // value={email}
                              onChange={(e) => {
                                onChange(e);
                                //vaildateNewUserForm()
                              }}
                              // ref={valUserEmailRef}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 ApplicationPadding">
                          <div className="">Email</div>
                          <div className="form-group mt-1">
                            <input
                              type="email"
                              className="form-control"
                              id="VisitorEmail"
                              name="VisitorEmail"
                              placeholder="Enter user email"
                              title="User Email"
                              value={formData ? formData.VisitorEmail : ""}
                              required
                              // value={email}
                              onChange={(e) => {
                                onChange(e);
                                //vaildateNewUserForm()
                              }}
                              // ref={valUserEmailRef}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <!-- Application details --> */}
                      <div className="row  ApplicationPadding">
                        <div className="row mt-2 ApplicationPadding">
                          <fieldset className="pb-3 rounded me-4">
                            <legend className="text-white p-1">
                              Application Details
                            </legend>
                            <div className="row me-4 RemovePaddingOnRight">
                              <div className="text">Appointment is</div>
                              <div className="row RemovePaddingOnRight">
                                <div className="col-md-4 ApplicationColumnDetails">
                                  <div className="text-white fs-5">
                                    Company Name:
                                  </div>
                                  <div className="form-group fs-5">
                                    {visitor ? visitor.VisitorCompanyName : ""}
                                  </div>
                                </div>
                              </div>
                              <div className="row me-4 pt-2 mb-2 RemovePaddingOnRight">
                                <div className="col-md-4 ApplicationColumnDetails">
                                  <div className="text-white fs-5">
                                    Date and Time:
                                  </div>
                                  <div className="form-group  fs-5">
                                    {visitor
                                      ? formatTime(
                                          visitor.VisitorAppointmentTimeStamp
                                        )
                                      : ""}
                                    <div
                                      className=" rounded-1 text-white p-1"
                                      onClick={() =>
                                        document
                                          .getElementById(
                                            "VisitorAppointmentTimeStamp"
                                          )
                                          .click()
                                      }
                                    >
                                      {/* <ROIcon
                                                                            icon='icoCalender'
                                                                            size={24}
                                                                            className='icoSVG newCalendar'
                                                                            ROiconAfterStyle='align-bottom p-0 m-0 px-1'
                                                                            tooltip='Calender'
                                                                            usefill='true'
                                                                            isLink={false}
                                                                        /> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 ApplicationColumnDetails">
                                  <div className="text-white fs-5">
                                    Appointment With:
                                  </div>
                                  <div className="form-group  fs-5">
                                    {visitor
                                      ? visitor.VisitorLocalContactName
                                      : ""}
                                  </div>
                                </div>
                                <div className="col-md-4 ApplicationColumnDetails">
                                  <div className="text-white fs-5">Reason:</div>
                                  <div className="form-group  fs-5">
                                    {visitor ? visitor.VisitorReason : ""}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div ref={msg} className="col-10 p-0 m-0"></div>
                    </div>
                  </div>
                </div>

                {/* <!-- Submit and Cancel button --> */}

                <div className="col-lg-2 mt-2  AppointmentFormGridContainer AppointmentFormGrid">
                  <div className="row float-end">
                    <div className="uploadButtonsSub-Can">
                      <div className="container  SubmitContainer">
                        {boolFormValidated ? (
                          <button
                            type="submit"
                            className="Submit rounded-1 text-white"
                          >
                            <ROIcon
                              icon="icoAccept"
                              size={24}
                              className="icoSVG w-100 p-0 m-0 fullDiv"
                              ROiconAfterStyle="align-bottom p-0 m-0 ps-2 fs-5"
                              //textafter='Employees'
                              //tooltip={cameras ? cameras[0].CameraName : ''}
                              usefill="true"
                              color1="#ffffff"
                              isLink={false}
                              textafter="Submit"
                              // onClickCallback={() => onCameraButton()}
                            />
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-secondary btn-block fs-5"
                            onClick={() => clearForm()}
                          >
                            Button
                          </button>
                        )}
                      </div>
                      <div className="container  CancelContainer  pt-2">
                        <button
                          type="button"
                          className="Cancel rounded-1 text-white "
                        >
                          <ROIcon
                            icon="icoCancel"
                            size={24}
                            className="icoSVG w-100 p-0 m-0 fullDiv"
                            //textafter='Employees'
                            //tooltip={cameras ? cameras[0].CameraName : ''}
                            usefill="true"
                            color1="#ffffff"
                            isLink={true}
                            to="/Visitors"
                            textafter="Cancel"
                            ROiconAfterStyle="align-bottom p-0 m-0 ps-1 fw-bold"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </form>
      ) : (
        "Form Submitted"
      )}
    </>
  );
};

export default Appointment;
